import SVG from '../components/SVG'
import React, { useState, useEffect } from 'react'
import Link from 'next/link'
import { setPageViewData } from '../utils/analytics'

const TermsConditionsModal = ({ handleDismiss, agreeTCVersion }) => {
  const [isChecked, setIsChecked] = useState(false)
  const [isDeclined, setIsDeclined] = useState(false)
  const UPDATE_TERMS_PRIVACY_PAGE_TYPE = 'Update Terms Privacy'

  useEffect(() => {
    setPageViewData(UPDATE_TERMS_PRIVACY_PAGE_TYPE, 'Account', 'Account')
  }, [])

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked)
    event.target.checked ? setIsDeclined(false) : setIsDeclined(true)
  }

  return (
    <section>
    <div className='modal-wrapper terms-condition-modal'>
       <h1>we've updated the terms and conditions.</h1>
       <label>Please review the updated T&Cs</label>
       <div className='input-wrap'>
            <Link href='/privacypolicy'>
                <a target="_blank" className='terms-model-container'>
                    <p>Privacy Policy</p>
                    <span><SVG name="arrow-right" label="Arrow-right" size="md" /></span>
                </a>
            </Link>
            <Link href='/termsandconditions'>
                <a target="_blank" className='terms-model-container'>
                    <p>Terms and Condition</p>
                    <span><SVG name="arrow-right" label="Arrow-right" size="md" /></span>
                </a>
            </Link>
       </div>
       <div className='form-wrap'>
            <div className="input-wrap email-checkmark">
                <input type="checkbox" className="regular-checkbox" checked={isChecked} onChange={handleCheckboxChange}/>
                <label>I acknowledge that I have read and agree to the above Terms and Conditions.</label>
            </div>
            {isDeclined && (<label className='error-label'>You need to agree to proceed to your account. Please check the box above to continue.</label>)}
            <div className="agree-button">
                {isChecked && <button className="btn__primary" onClick={() => agreeTCVersion()}>I AGREE</button>}
            </div>
            <div className="decline-button">
                <button className="btn__secondary" onClick={() => !isChecked && setIsDeclined(true)}>I DECLINE</button>
            </div>
        </div>
    </div>
    </section>
  )
}

export default TermsConditionsModal
