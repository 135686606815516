import { Component } from 'react';
import getConfig from "next/config";
const { publicRuntimeConfig } = getConfig();
let BranchSDK;

class BranchIO extends Component {
    
    createDeepLink(desktop_url, og_title, og_description, og_image_url, callback) {
        if (typeof window !== 'undefined' && publicRuntimeConfig.BRANCH_KEY) {
            
            BranchSDK = require('branch-sdk');
            var linkData = {
                tags: [],
                channel: '',
                feature: '',
                stage: '',
                data: {
                    '$desktop_url': desktop_url,
                    '$android_url': desktop_url,
                    '$ios_url': desktop_url,
                    '$og_title': og_title,
                    '$og_description': og_description,
                    '$og_image_url': og_image_url && og_image_url.split(",")[0]
                }
            };
            BranchSDK.link(linkData, (err, link) => {
                callback(link);
            });
        }
    }

    logEvent(event,custom_data,content_items,event_alias){
        if (typeof window !== 'undefined' && publicRuntimeConfig.BRANCH_KEY) {
            BranchSDK = require('branch-sdk');
            BranchSDK.logEvent(
                event,
                custom_data,
                content_items,
                event_alias,
                function(err) { console.log(err); }
             );
        }
        
    }

     getPopupFeature () {
        let popup_height = 400;
        let popup_width = 800;
        var leftPosition, topPosition;
        //Allow for borders.
        leftPosition = (window.screen.width / 2) - ((popup_width / 2) + 10);
        //Allow for title and status bars.
        topPosition = (window.screen.height / 2) - ((popup_height / 2) + 50);
        var windowFeatures = "status=no,height=" + popup_height + ",width=" + popup_width + ",resizable=yes,left=" + leftPosition + ",top=" + topPosition + ",screenX=" + leftPosition + ",screenY=" + topPosition + ",toolbar=no,menubar=no,scrollbars=no,location=no,directories=no";
        return windowFeatures;
    }

    OpenTwitterLink(deepLink, title, image_url, slug) {
        deepLink = deepLink + "?~channel=twitter&~feature=websdk&~campaign=" + slug
        var twitterSharerLink = `https://twitter.com/share?url=${deepLink}&text=${title}`;
        window.open(twitterSharerLink,'sharer', this.getPopupFeature());
        return false;
    }

    OpenFacebookLink(deepLink, title, image_url, slug) {
        deepLink = deepLink + "?~channel=facebook&~feature=websdk&~campaign=" + slug
        var facebookLink = `http://www.facebook.com/sharer.php?u=${deepLink}`;
        window.open(facebookLink,'sharer', this.getPopupFeature());
        return false;
    }

    OpenPinterestLink(deepLink, title, image_url, slug) {
        deepLink = deepLink + "?~channel=pinterset&~feature=websdk&~campaign=" + slug
        var pinterstSharerLink = `http://pinterest.com/pin/create/button/?url=${deepLink}`;
        window.open(pinterstSharerLink,'sharer', this.getPopupFeature());
        return false;
    }

}

export default BranchIO