import React from 'react'
import SVG from './SVG'

const AccountLockedModal = ({ handleDismiss }) => {
  if (typeof window === 'undefined') {
    return null
  }

  return (
    <section>
      <button onClick={() => handleDismiss()} className="close" id="account-locked-close" ><SVG name="close" label="Close" size="md" /></button>
      <h3>your account is locked</h3>
      <SVG name="alert-full" label="alert-full" size="xl" />
      <p>Your account has been locked for security purposes. Please try to sign in again in 20 minutes.</p>
      <button className="btn__primary" onClick={() => handleDismiss()}>
        OK
      </button>
    </section>
  )
}

export default AccountLockedModal
