import React, { useContext } from 'react'
import getConfig from 'next/config'
import Context from '../context'
import { useAuthAPI } from '../hooks/api.hooks'
import SVG from './SVG'

const { publicRuntimeConfig } = getConfig()

const VerificationCodeErrorModal = ({ handleDismiss }) => {
  const { state } = useContext(Context)
  const authAPICall = useAuthAPI()

  const handleOnClick = (e) => {
    authAPICall(
      'user',
      'phone',
      'GET',
      {
        popkey: publicRuntimeConfig.POP_KEY
      },
      {},
      {
        phonenumber: state.user.phone
      }
    ).then(handleDismiss)
  }

  return (
    <div className='verification-code-error-modal'>
      <SVG name="alert-full" label="alert-full" size="xl" />
      <label>Your verification code was not<br />correct.  Please try again.</label>
      <button
        className='btn__primary btn__primary--full'
        onClick={handleOnClick}
      >
        GET A NEW CODE
      </button>
    </div>
  )
}

export default VerificationCodeErrorModal
